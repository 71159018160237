/* src/assets/styles/main.css */

/* Global Styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    color: #007bff;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Header Styles */
  header {
    background-color: #343a40;
    padding: 10px 0;
  }
  
  header nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  header nav ul li {
    margin: 0 15px;
  }
  
  header nav ul li a {
    color: #fff;
    font-size: 16px;
  }
  
  /* Footer Styles */
  footer {
    background-color: #343a40;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  /* Home Page Styles */
  .home {
    text-align: center;
    margin-top: 50px;
  }
  
  .home h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  .home p {
    font-size: 1.2em;
    color: #666;
  }
  
  /* Profile Page Styles */
  .profile {
    padding: 20px;
  }
  
  .profile h1 {
    font-size: 2em;
    color: #333;
  }
  
  .profile p {
    font-size: 1em;
    color: #666;
  }
  
  /* Add more styles for other pages as needed */
  