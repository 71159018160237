/* src/pages/Community.css */

.community {
    padding: 20px;
  }
  
  .community h1 {
    font-size: 2em;
    color: #333;
  }
  
  .community p {
    font-size: 1em;
    color: #666;
  }
  