/* src/pages/WorkoutPlans.css */

.workout-plans {
    padding: 20px;
  }
  
  .workout-plans h1 {
    font-size: 2em;
    color: #333;
  }
  
  .workout-plans p {
    font-size: 1em;
    color: #666;
  }
  