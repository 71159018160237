/* src/pages/ActivityTracker.css */

.activity-tracker {
    padding: 20px;
  }
  
  .activity-tracker h1 {
    font-size: 2em;
    color: #333;
  }
  
  .activity-tracker p {
    font-size: 1em;
    color: #666;
  }
  