/* src/pages/Signup.css */

.signup {
    text-align: center;
    margin-top: 50px;
  }
  
  .signup h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  .signup form {
    margin-top: 20px;
  }
  
  .signup form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .signup form input {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
  }
  
  .signup form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  