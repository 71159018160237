/* src/pages/Education.css */

.education {
    padding: 20px;
  }
  
  .education h1 {
    font-size: 2em;
    color: #333;
  }
  
  .education p {
    font-size: 1em;
    color: #666;
  }
  