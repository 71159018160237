/* src/pages/NutritionTips.css */

.nutrition-tips {
    padding: 20px;
  }
  
  .nutrition-tips h1 {
    font-size: 2em;
    color: #333;
  }
  
  .nutrition-tips p {
    font-size: 1em;
    color: #666;
  }
  