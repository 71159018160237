/* src/pages/Settings.css */

.settings {
    padding: 20px;
  }
  
  .settings h1 {
    font-size: 2em;
    color: #333;
  }
  
  .settings p {
    font-size: 1em;
    color: #666;
  }
  