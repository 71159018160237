/* src/pages/Login.css */

.login {
    text-align: center;
    margin-top: 50px;
  }
  
  .login h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  .login form {
    margin-top: 20px;
  }
  
  .login form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .login form input {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
  }
  
  .login form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  