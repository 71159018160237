/* src/pages/Profile.css */

/* Custom styles for Profile component */
.container {
    max-width: 600px;
  }
  
  .card {
    background-color: #f8f9fa;
  }
  
  .card-title {
    font-size: 1.5em;
    color: #333;
  }
  
  .card-text {
    font-size: 1.1em;
    color: #666;
  }
  